function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
import FullParagraphAdvancedFieldsConfig from "segments/desktop/core-components/full-paragraph/advanced-fields-config";
import AdvancedThemeConfig from "segments/core-configs/advanced-theme-config";
export default {
    type: FIELD_TYPES.NESTED,
    label: "",
    fields: _object_spread_props(_object_spread({}, SharedAdvancedFieldsConfig, AdvancedThemeConfig), {
        fullParagraphAdvancedConfig: {
            type: FIELD_TYPES.NESTED,
            label: "Paragraph Advanced Config",
            fields: FullParagraphAdvancedFieldsConfig
        },
        desktopHorizontalPadding: {
            type: FIELD_TYPES.TEXT,
            label: "Desktop Horizontal Padding"
        },
        borderOpacity: {
            type: FIELD_TYPES.TEXT,
            label: "Border opacity"
        },
        disableCardsHoverStyle: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Disable cards hover style"
        },
        focusOnSelect: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Focus on slide click (default is true)"
        },
        slideBreakingPoints: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Slide Breaking Points",
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    breakpoint: {
                        type: FIELD_TYPES.TEXT,
                        label: "Breakpoint"
                    },
                    slidesToShow: {
                        type: FIELD_TYPES.TEXT,
                        label: "Cards to show"
                    }
                }
            }
        }
    })
};
