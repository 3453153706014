// plop_marker:layouts_import
import CarouselTabs from "./carousel-tabs/index.js";
import UsecasesGalleryTabs from "./usecases-gallery-tabs/index.js";
import SideBySideProductSwitcherWithProductTabs from "./side-by-side-product-switcher-with-product-tabs/index.js";
import TwoThirdsTextAndProductsHorizontalTabs from "./two-thirds-text-and-products-horizontal-tabs/index.js";
import ProductsHorizontalTimerTabs from "./products-horizontal-timer-tabs/index.js";
import SideBySideParagraphAndProductsTabs from "./side-by-side-paragraph-and-products-tabs/index.js";
import TimelineTitledTabTabs from "./timeline-titled-tab-tabs/index.js";
import SideBySideTabsAndProcessTabs from "./side-by-side-tabs-and-process-tabs/index.js";
import SideBySideTabsAndTestimonialTabs from "./side-by-side-tabs-and-testimonial-tabs/index.js";
import PersonasTabs from "./personas-tabs/index.js";
import SideBySideBoardViewsComponentTabs from "./side-by-side-board-views-component-tabs/index.js";
import UseCasesTabs from "./use-cases-tabs/index.js";
import MondayDemoTabs from "./monday-demo-tabs/index.js";
import BuildingBlocksTabs from "./building-blocks-tabs/index.js";
import BoardTabs from "./board-tabs/index.js";
import TimelineTabs from "./timeline-tabs/index.js";
import ColoredTimerVerticalTabs from "./colored-timer-vertical-tabs/index.js";
import TimerVerticalTabs from "./timer-vertical-tabs/index.js";
import VerticalTabs from "./vertical-tabs/index.js";
import RegularTabs from "./regular-tabs/index.js";
export default {
    // plop_marker:layouts_add_layout
    CarouselTabs,
    UsecasesGalleryTabs,
    SideBySideProductSwitcherWithProductTabs,
    TwoThirdsTextAndProductsHorizontalTabs,
    ProductsHorizontalTimerTabs,
    SideBySideParagraphAndProductsTabs,
    TimelineTitledTabTabs,
    SideBySideTabsAndProcessTabs,
    SideBySideTabsAndTestimonialTabs,
    PersonasTabs,
    SideBySideBoardViewsComponentTabs,
    UseCasesTabs,
    MondayDemoTabs,
    BuildingBlocksTabs,
    BoardTabs,
    TimelineTabs,
    ColoredTimerVerticalTabs,
    TimerVerticalTabs,
    VerticalTabs,
    RegularTabs
};
