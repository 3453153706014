// plop_marker:layouts_import
import HubspotDynamicFormIframe from "./hubspot-dynamic-form-iframe/index.js";
import EmbeddedBoardIframe from "./embedded-board-iframe/index.js";
import SideBySideHubspotForm from "./side-by-side-hubspot-form/index.js";
import IframeDynamicWidthIframe from "./iframe-dynamic-width-iframe/index.js";
export default {
    // plop_marker:layouts_add_layout
    HubspotDynamicFormIframe,
    EmbeddedBoardIframe,
    SideBySideHubspotForm,
    IframeDynamicWidthIframe
};
