import Layouts from "./layouts/index.js";
const { // plop_marker:segment_index_import_layout
CarouselTabs, UsecasesGalleryTabs, SideBySideProductSwitcherWithProductTabs, TwoThirdsTextAndProductsHorizontalTabs, ProductsHorizontalTimerTabs, SideBySideParagraphAndProductsTabs, TimelineTitledTabTabs, SideBySideTabsAndProcessTabs, SideBySideTabsAndTestimonialTabs, PersonasTabs, SideBySideBoardViewsComponentTabs, UseCasesTabs, MondayDemoTabs, BuildingBlocksTabs, BoardTabs, TimelineTabs, ColoredTimerVerticalTabs, TimerVerticalTabs, VerticalTabs, RegularTabs } = Layouts;
export default {
    // plop_marker:segment_index_add_layout
    CarouselTabsComponent: {
        type: "CarouselTabsComponent",
        title: "Carousel",
        imgSrc: "/static/img/segments/layouts/carousel-tabs.png",
        config: CarouselTabs.CarouselTabsConfig,
        advancedConfig: CarouselTabs.CarouselTabsAdvancedConfig,
        component: CarouselTabs.CarouselTabsComponent
    },
    UsecasesGalleryTabsComponent: {
        type: "UsecasesGalleryTabsComponent",
        title: "Usecases Gallery",
        imgSrc: "/static/img/segments/layouts/usecases-gallery-tabs.png",
        config: UsecasesGalleryTabs.UsecasesGalleryTabsConfig,
        advancedConfig: UsecasesGalleryTabs.UsecasesGalleryTabsAdvancedConfig,
        component: UsecasesGalleryTabs.UsecasesGalleryTabsComponent
    },
    SideBySideProductSwitcherWithProductTabsComponent: {
        type: "SideBySideProductSwitcherWithProductTabsComponent",
        title: "Side By Side Product Switcher With Product",
        imgSrc: "/static/img/segments/layouts/side-by-side-product-switcher-with-product-tabs.png",
        config: SideBySideProductSwitcherWithProductTabs.SideBySideProductSwitcherWithProductTabsConfig,
        advancedConfig: SideBySideProductSwitcherWithProductTabs.SideBySideProductSwitcherWithProductTabsAdvancedConfig,
        component: SideBySideProductSwitcherWithProductTabs.SideBySideProductSwitcherWithProductTabsComponent
    },
    TwoThirdsTextAndProductsHorizontalTabsComponent: {
        type: "TwoThirdsTextAndProductsHorizontalTabsComponent",
        title: "Two Thirds Text And Products Horizontal",
        imgSrc: "/static/img/segments/layouts/two-thirds-text-and-products-horizontal-tabs.png",
        config: TwoThirdsTextAndProductsHorizontalTabs.TwoThirdsTextAndProductsHorizontalTabsConfig,
        advancedConfig: TwoThirdsTextAndProductsHorizontalTabs.TwoThirdsTextAndProductsHorizontalTabsAdvancedConfig,
        component: TwoThirdsTextAndProductsHorizontalTabs.TwoThirdsTextAndProductsHorizontalTabsComponent
    },
    ProductsHorizontalTimerTabsComponent: {
        type: "ProductsHorizontalTimerTabsComponent",
        title: "Products Horizontal Timer",
        imgSrc: "/static/img/segments/layouts/products-horizontal-timer-tabs.png",
        config: ProductsHorizontalTimerTabs.ProductsHorizontalTimerTabsConfig,
        advancedConfig: ProductsHorizontalTimerTabs.ProductsHorizontalTimerTabsAdvancedConfig,
        component: ProductsHorizontalTimerTabs.ProductsHorizontalTimerTabsComponent
    },
    SideBySideParagraphAndProductsTabsComponent: {
        type: "SideBySideParagraphAndProductsTabsComponent",
        title: "Side By Side Paragraph And Products",
        imgSrc: "/static/img/segments/layouts/side-by-side-paragraph-and-products-tabs.png",
        config: SideBySideParagraphAndProductsTabs.SideBySideParagraphAndProductsTabsConfig,
        advancedConfig: SideBySideParagraphAndProductsTabs.SideBySideParagraphAndProductsTabsAdvancedConfig,
        component: SideBySideParagraphAndProductsTabs.SideBySideParagraphAndProductsTabsComponent
    },
    TimelineTitledTabTabsComponent: {
        type: "TimelineTitledTabTabsComponent",
        title: "Timeline Titled Tab",
        imgSrc: "/static/img/segments/layouts/timeline-titled-tab-tabs.png",
        config: TimelineTitledTabTabs.TimelineTitledTabTabsConfig,
        advancedConfig: TimelineTitledTabTabs.TimelineTitledTabTabsAdvancedConfig,
        component: TimelineTitledTabTabs.TimelineTitledTabTabsComponent
    },
    SideBySideTabsAndProcessTabsComponent: {
        type: "SideBySideTabsAndProcessTabsComponent",
        title: "Side By Side Tabs And Process",
        imgSrc: "/static/img/segments/layouts/side-by-side-tabs-and-process-tabs.png",
        config: SideBySideTabsAndProcessTabs.SideBySideTabsAndProcessTabsConfig,
        advancedConfig: SideBySideTabsAndProcessTabs.SideBySideTabsAndProcessTabsAdvancedConfig,
        component: SideBySideTabsAndProcessTabs.SideBySideTabsAndProcessTabsComponent
    },
    SideBySideTabsAndTestimonialTabsComponent: {
        type: "SideBySideTabsAndTestimonialTabsComponent",
        title: "Side By Side Tabs And Testimonial",
        imgSrc: "/static/img/segments/layouts/side-by-side-tabs-and-testimonial-tabs.png",
        config: SideBySideTabsAndTestimonialTabs.SideBySideTabsAndTestimonialTabsConfig,
        advancedConfig: SideBySideTabsAndTestimonialTabs.SideBySideTabsAndTestimonialTabsAdvancedConfig,
        component: SideBySideTabsAndTestimonialTabs.SideBySideTabsAndTestimonialTabsComponent
    },
    PersonasTabsComponent: {
        type: "PersonasTabsComponent",
        title: "Personas",
        imgSrc: "/static/img/segments/layouts/personas-tabs.png",
        config: PersonasTabs.PersonasTabsConfig,
        advancedConfig: PersonasTabs.PersonasTabsAdvancedConfig,
        component: PersonasTabs.PersonasTabsComponent
    },
    SideBySideBoardViewsComponentTabsComponent: {
        type: "SideBySideBoardViewsComponentTabsComponent",
        title: "Side By Side Board Views Component",
        imgSrc: "/static/img/segments/layouts/side-by-side-board-views-component-tabs.png",
        config: SideBySideBoardViewsComponentTabs.SideBySideBoardViewsComponentTabsConfig,
        advancedConfig: SideBySideBoardViewsComponentTabs.SideBySideBoardViewsComponentTabsAdvancedConfig,
        component: SideBySideBoardViewsComponentTabs.SideBySideBoardViewsComponentTabsComponent
    },
    UseCasesTabsComponent: {
        type: "UseCasesTabsComponent",
        title: "Use Cases",
        imgSrc: "/static/img/segments/layouts/use-cases-tabs.png",
        config: UseCasesTabs.UseCasesTabsConfig,
        advancedConfig: UseCasesTabs.UseCasesTabsAdvancedConfig,
        component: UseCasesTabs.UseCasesTabsComponent
    },
    MondayDemoTabsComponent: {
        type: "MondayDemoTabsComponent",
        title: "Monday Demo",
        imgSrc: "/static/img/segments/layouts/monday-demo-tabs.png",
        config: MondayDemoTabs.MondayDemoTabsConfig,
        advancedConfig: MondayDemoTabs.MondayDemoTabsAdvancedConfig,
        component: MondayDemoTabs.MondayDemoTabsComponent
    },
    BuildingBlocksTabsComponent: {
        type: "BuildingBlocksTabsComponent",
        title: "Building Blocks",
        imgSrc: "/static/img/segments/layouts/building-blocks-tabs.png",
        config: BuildingBlocksTabs.BuildingBlocksTabsConfig,
        advancedConfig: BuildingBlocksTabs.BuildingBlocksTabsAdvancedConfig,
        component: BuildingBlocksTabs.BuildingBlocksTabsComponent
    },
    BoardTabsComponent: {
        type: "BoardTabsComponent",
        title: "Board",
        imgSrc: "/static/img/segments/layouts/board-tabs.png",
        config: BoardTabs.BoardTabsConfig,
        advancedConfig: BoardTabs.BoardTabsAdvancedConfig,
        component: BoardTabs.BoardTabsComponent
    },
    TimelineTabsComponent: {
        type: "TimelineTabsComponent",
        title: "Timeline",
        imgSrc: "/static/img/segments/layouts/timeline-tabs.png",
        config: TimelineTabs.TimelineTabsConfig,
        advancedConfig: TimelineTabs.TimelineTabsAdvancedConfig,
        component: TimelineTabs.TimelineTabsComponent
    },
    ColoredTimerVerticalTabsComponent: {
        type: "ColoredTimerVerticalTabsComponent",
        title: "Colored Timer Vertical",
        imgSrc: "/static/img/segments/layouts/colored-timer-vertical-tabs.png",
        config: ColoredTimerVerticalTabs.ColoredTimerVerticalTabsConfig,
        advancedConfig: ColoredTimerVerticalTabs.ColoredTimerVerticalTabsAdvancedConfig,
        component: ColoredTimerVerticalTabs.ColoredTimerVerticalTabsComponent
    },
    TimerVerticalTabsComponent: {
        type: "TimerVerticalTabsComponent",
        title: "Timer Vertical",
        imgSrc: "/static/img/segments/layouts/timer-vertical-tabs.png",
        config: TimerVerticalTabs.TimerVerticalTabsConfig,
        advancedConfig: TimerVerticalTabs.TimerVerticalTabsAdvancedConfig,
        component: TimerVerticalTabs.TimerVerticalTabsComponent
    },
    VerticalTabsComponent: {
        type: "VerticalTabsComponent",
        title: "Vertical",
        imgSrc: "/static/img/segments/layouts/vertical-tabs.png",
        config: VerticalTabs.VerticalTabsConfig,
        advancedConfig: VerticalTabs.VerticalTabsAdvancedConfig,
        component: VerticalTabs.VerticalTabsComponent
    },
    RegularTabsComponent: {
        type: "RegularTabsComponent",
        title: "Regular",
        imgSrc: "/static/img/segments/layouts/regular-tabs.png",
        config: RegularTabs.RegularTabsConfig,
        advancedConfig: RegularTabs.RegularTabsAdvancedConfig,
        component: RegularTabs.RegularTabsComponent
    }
};
